<template>
    <div>
        <h1>C-Wurf</h1>
        <h3 style="margin-bottom: 64px">19.08.2024</h3>

        <div class="image">
             <img src="../../assets/img/C-Wurf/C-Wurf-Welpen.jpeg" alt="C-Wurf">
        </div>

        <DogParents :mom="mom" :dad="dad"/>

        <div class="image">
             <img src="../../assets/img/comingSoon.jpg" alt="C-Wurf">
        </div>
    </div>
</template>

<script>
import ImageCard from '../../components/ImageCard.vue';
import DogParents from '../../components/DogParents.vue';

export default {
  name: 'App',
  data() {
    return {
        mom: {
            name: "Arya vom Gonsbachtal",
            bday: "geb.01.01.2022",
            img_face: require("../../assets/img/Arya_Face_Small.jpg"),
            img_side: require("../../assets/img/Arya_Side.jpg")
        },
        dad: {
            name: "Duke vom Arenholzer See",
            bday: "geb. 16.09.2019",
            img_face: require("../../assets/img/Duke_Face.jpeg"),
            img_side: require("../../assets/img/Duke_Side.jpg")
        }
    }
  },
  components: {
    ImageCard,
    DogParents
    },
    props: ["onZuchtstaette"],
    emits: ["update-zuchtstaette-nav-on", "update-zuchtstaette-nav-off"],
    mounted() {
        this.$emit("update-zuchtstaette-nav-on")
    },
    unmounted() {
        this.$emit("update-zuchtstaette-nav-off")
    }
}
</script>

<style scoped>
:root {
  --backgroundColor: #dcdece;
  --mainText: #333D29;
  --mainTextLight: #70875A;
  --linkText: #936639;
  --focusedText: #582F0E;
  --higlightedText: #04A0A2;
}

h1 {
    margin-bottom: 0px;
}

h3 {
    color: var(--mainTextLight);
}

h4 {
    color: var(--mainTextLight);
    margin-bottom: 16px;
}

h2 {
    margin-bottom: 0px;
}

.image {
    display: grid;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 750px;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    border-radius: 8px;
}

.puppies {
    display: grid;
    gap: 64px 0px;
}

h4 {
    display: flex;
}

i {
    color: var(--mainTextLight);
    font-size: 0.8em;
}
</style>